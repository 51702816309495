<template>
  <div class="safe-area-inset-bottom resources-page">
    <div class="header-box">
      <div class="top-search dfr flex-center">
        <van-search
          v-model="keyword"
          placeholder="请输入想对接的资源内容"
          @blur="searchBlur"
          :left-icon="leftIcon"
          class="flex1"
        />
        <div class="search-right dfr cross-center por">
          <div
            :class="[hasFilter || activeCate ? 'fctheme' : 'fc666']"
            class="category-name fs28 text-overflow"
          >
            {{ categoryName }}
          </div>
          <div class="cate-icon">
            <img
              v-if="hasFilter || activeCate"
              src="../assets/images/cate-active.png"
              class="w100"
              alt=""
            />
            <img
              v-else
              src="../assets/images/cate-default.png"
              class="w100"
              alt=""
            />
          </div>
          <van-dropdown-menu class="cate-filter ">
            <van-dropdown-item
              v-model="categoryId"
              title=""
              ref="resources-item"
              @open="categoryOpen"
              @close="categoryClose"
            >
              <div class="categoryList">
                <div
                  :class="[item.active ? 'cate-active' : '']"
                  class="category-item"
                  v-for="(item, index) in categoryList"
                  :key="index"
                  @click="activeCategory(index)"
                >
                  {{ item.name }}
                </div>
              </div>
            </van-dropdown-item>
          </van-dropdown-menu>
        </div>
      </div>
    </div>

    <!-- 资源列表 -->
    <van-list
      v-model="loading"
      loading-text=""
      :offset="200"
      :finished="finished"
      @load="onLoad"
      class="resources-list-wrap pb28"
      v-if="resourcesList.length"
    >
      <div
        class="resources-item"
        v-for="(item, index) in resourcesList"
        :key="index"
        @click="goDetail(item.id)"
      >
        <div v-if="item.name" class="item-inner-box mlauto mrauto mt28">
          <div class="item-box">
            <div class="dfr main-between">
              <div class="dfr">
                <!-- 头像 -->
                <div class="item-portrait-box">
                  <!-- 用户头像 -->
                  <img
                    v-if="item.avatar"
                    :src="item.avatar"
                    alt=""
                    class="w100 h100 object-cover"
                  />
                  <!-- 默认头像 -->
                  <img
                    v-else
                    src="../assets/images/default-portrait.png"
                    alt=""
                    class="w100 h100"
                  />
                  <!-- 徽章 -->
                  <div
                    v-if="item.is_auth || item.is_major_auth"
                    class="item-badge-box"
                  >
                    <img
                      src="../assets/images/home-emblem.png"
                      alt=""
                      class="w100 h100"
                    />
                  </div>
                </div>
                <!-- 信息 -->
                <div class="item-message flex1">
                  <div class="dfr cross-center">
                    <div class="dfr cross-center">
                      <!-- 姓名 -->
                      <div class="fs32 fw600 fc333">{{ item.name }}</div>
                    </div>
                    <!-- 火焰标志 -->
                    <div v-if="item.is_recommend" class="is-hot ml16">
                      <img
                        src="../assets/images/hot.png"
                        class="w100 h100"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="item-com-job mt12">
                    <!-- 职位 -->
                    <div class="fs24 fw400 fc999 item-job">
                      {{ item.job }}
                    </div>

                    <div
                      v-if="item.job && item.company"
                      class="item-job"
                      style="width: 0.02rem;height: 0.2rem;background: #CCCCCC;margin:0 0.12rem"
                    />

                    <div class="fs24 fw400 fc999 item-company">
                      {{ item.company }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 描述 -->
          <div
            v-if="item.company_service || item.company_ability"
            class="fs28 fc666 description"
          >
            {{ item.company_service || item.company_ability }}
          </div>

          <div
            v-if="
              (item.company_service || item.company_ability) && item.major_field
            "
            class="fieldBox-line"
          ></div>

          <!-- 领域 -->
          <div v-if="item.major_field" class="fs24 fieldBox">
            # {{ item.major_field.name }} #
          </div>
        </div>
      </div>
    </van-list>
    <empty v-else content="暂无搜索结果"></empty>

    <tab-bar active="2"></tab-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftIcon: require("../assets/images/search.png"),
      resourcesList: [],
      keyword: "",
      loading: false,
      page: 1,
      pageSize: 10,
      finished: false,
      activeCate: false,
      hasFilter: false,
      categoryId: "",
      categoryName: "分类",
      categoryList: []
    };
  },
  created() {
    this.initPage();
  },
  activated() {
    this.$title("资源对接");
  },
  mounted() {},
  methods: {
    async initPage() {
      this.initList();
      this.getIndustryList();
    },
    async initList() {
      this.resourcesList = await this.getResourcesList();
      if (this.total < this.pageSize) {
        this.loading = false;
        this.finished = true;
      } else {
        this.loading = false;
        this.finished = false;
      }
    },
    async getResourcesList() {
      let list = [];
      const data = {
        page: this.page,
        keyword: this.keyword,
        pageSize: this.pageSize,
        field_id: this.categoryId
      };
      const resp = await this.$API
        .get("resourcesList", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.total = resp.data.list.length;
        list = resp.data.list;
      }
      return list;
    },
    async onLoad() {
      if (!this.finished) {
        this.page++;
        const resourcesList = await this.getResourcesList();
        if (resourcesList.length < this.pageSize) {
          this.resourcesList = [...this.resourcesList, ...resourcesList];
          this.loading = false;
          this.finished = true;
        } else {
          this.resourcesList = [...this.resourcesList, ...resourcesList];
          this.loading = false;
          this.finished = false;
        }
      }
    },
    // 获取行业列表
    async getIndustryList() {
      const resp = await this.$API.get("fieldList").then(resp => resp);
      if (resp.code == 1000) {
        resp.data.unshift({
          id: "",
          name: "全部",
          active: true
        });
        this.categoryList = resp.data;
      }
    },
    searchBlur() {
      this.page = 1;
      this.initList();
    },
    categoryOpen() {
      this.activeCate = true;
    },
    categoryClose() {
      this.activeCate = false;
    },
    activeCategory(index) {
      this.categoryList.forEach(item => {
        item.active = false;
      });

      this.categoryList[index].active = true;
      this.categoryId = this.categoryList[index].id;
      this.categoryName = this.categoryList[index].name;
      if (this.categoryName == "全部") {
        this.categoryName = "分类";
      }
      if (this.categoryId) {
        this.hasFilter = true;
      } else {
        this.hasFilter = false;
      }
      this.initList();
      this.$refs["resources-item"].toggle(false);
    },
    goDetail(id) {
      this.$go("resourcesDetail", { id });
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.resources-page {
  background: #f7f7f7;
  min-height: 100vh;
  .header-box {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
    height: 0.96rem;
    .top-search {
      height: 0.96rem;
      width: 7.5rem;
      background: #ffffff;
      // border: 1px solid red;
    }
    .search-right {
      padding: 0 0.36rem 0 0;
      .category-name {
        max-width: 5rem;
      }
      .cate-icon {
        width: 0.16rem;
        margin-left: 0.12rem;
      }
      .cate-filter {
        position: absolute;
        width: 100%;
        right: 0;
        .categoryList {
          padding: 0.18rem 0.18rem 0.36rem 0.36rem;

          .category-item {
            min-width: 1.56rem;
            height: 0.6rem;
            line-height: 0.6rem;
            text-align: center;
            padding: 0 0.28rem;
            border: 1px solid transparent;
            transition: all 0.5s;
            display: inline-block;
            margin: 0.18rem 0.18rem 0 0;
            border-radius: 0.3rem;
            background: #f4f4f4;
            font-size: 0.24rem;
            &.cate-active {
              background: rgba(203, 144, 37, 0.06);
              color: #cb9025;
              border-color: #cb9025;
            }
          }
        }
      }
    }
  }
  .resources-item {
    padding-bottom: 0.28rem;
    .item-inner-box {
      width: 6.94rem;
      background: #ffffff;
      border-radius: 0.12rem;
      box-sizing: border-box;
      overflow: hidden;
    }
    .item-box {
      padding: 0.36rem 0 0.36rem 0.36rem;
      .item-portrait-box {
        width: 0.96rem;
        height: 0.96rem;
        position: relative;
        img {
          border-radius: 50%;
          object-fit: cover;
        }
        .item-badge-box {
          width: 0.28rem;
          height: 0.28rem;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            position: absolute;
            object-fit: cover;
          }
        }
      }
      .item-message {
        margin-left: 0.2rem;
        .is-hot {
          width: 0.24rem;
          height: 0.28rem;
        }
        .item-com-job {
          white-space: normal;
          word-break: break-all;
          .item-job {
            display: inline-block;
          }
          .item-company {
            display: inline;
          }
        }
      }
      .item-delete {
        width: 0.28rem;
        height: 0.28rem;
        margin-right: 0.36rem;
        img {
          object-fit: cover;
        }
      }
    }
    .description {
      width: 6.22rem;
      margin: 0 auto 0.36rem;
    }

    .fieldBox-line {
      width: 6.22rem;
      height: 0.01rem;
      margin: 0 auto;
      background: #eee;
      padding: 0 0.36rem;
    }

    .fieldBox {
      width: 6.22rem;
      margin: 0 auto;
      color: #b2b2b2;
      padding: 0.26rem 0;
    }
  }
}
</style>
